<template>
<div>References</div>
</template>

<script>
export default {
  name: "ReferenceSection"
};
</script>

<style lang="scss" src="./ReferenceSection.scss" ></style>

    