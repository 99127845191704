<template src="./References.html"></template>

<script>
export default {
  name: "References",
  props: {
    mainDesc: String,
  },
};
</script>

<style lang="scss" src="./References.scss" scoped></style>