<template>
    <div class="riskRecGraph_outer"></div>
</template>

<script>
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { delay } from "q";
 var scrollPos = 0;
 var Counter = 0;
export default {
  name: "RiskRecGraph'",
  props: {
      name: String,
  },
  mounted() {
   
    if (window.innerWidth > 767) {
       gsap.registerPlugin(ScrollTrigger);
      ScrollTrigger.create({
      trigger: ".scroll-trigger",
      markers: false,
      start: "top +=30%",/* animate when top of trigger is 30% from top of viewport */
      onEnter() {
        document.querySelector(".image1").classList.add("animationimage1");
        document.querySelector(".image2").classList.add("animationimage2");
        document.querySelector(".image3").classList.add("animationimage3");
        document.querySelector(".text1").classList.add("animationtext1");
        document.querySelector(".text2").classList.add("animationtext2");
      },
    });
}else{
  gsap.registerPlugin(ScrollTrigger);
      ScrollTrigger.create({
      trigger: ".scroll-trigger",
      markers: false,
      start: "top +=30%",/* animate when top of trigger is 30% from top of viewport */
      onEnter() {
        document.querySelector(".image1").classList.add("animationimage1");
        document.querySelector(".image2").classList.add("animationimage2");
        document.querySelector(".image3").classList.add("animationimage3");
        document.querySelector(".text1").classList.add("animationtext1");
        document.querySelector(".text2").classList.add("animationtext2");
      },
    });
}

  }
};
</script>

<style lang="scss" scoped>
.riskRecGraph_outer {
  display: flex;
  .riskRecGraph {      
      display: -webkit-box;
      gap:3px;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      max-width: 770px;
      flex-flow: wrap;
      margin-bottom: 25px;

      @include media("<phone") {
        min-height: 269px;
          gap: 1px;
          margin: 0;
          padding: 0;
        }  
         @include media("<verySmallPhone") {
          min-height: 189px;
          }    

      li {
          background: $lightGray;
          width: 14px;
          height: 14px;
          border-radius: 50%;
          margin: 3px;
          opacity: 10%;
          @include media("<phone") {
         width: 9px;
          height: 8px;
           margin: 2.5px 0 0 1px;
          }

          @media only screen and (max-width: 768px) and (min-width: 500px)  {
             margin: 2.36px;
          }

          @include media("<iphonePhone") {
         width: 7.5px;
          height: 7.5px;
           margin: 2.5px 0 0 1px;
          }

          @include media("<phonePort") {
            margin: 2px 0.5px;
          }

            @include media("<verySmallPhone") {
         width: 6.5px;
          height: 6.5px;
           margin: 2.5px 0 0 1px;
          }


          &.active {
              background: #93B8CF ;
          }
          &.activegray {
              background: #793987 ;
          }
          &.activegrayfist {
            animation: fadeIn10to100 1s ease-in both;
            animation-delay: 0s;
          }
      }
  }
}
</style>