<template>
  <div class="brand-footer">
    <div class="brand-footer__contents"> 
      <div class="brand-footer__contents--left">
        <div class="brand-footer__contents--left__logo">
          <img src="@/Assets/Img/DET_accessibility_logo_KO_v1.svg" alt="" />
          <img src="@/Assets/Img/Merck-Logo.svg" alt="" />
        </div>
       
      </div> 
      <div class="brand-footer__contents--right">
        <div class="brand-footer__disclaimer">
         <p>Copyright © 2023 Merck & Co., Inc., Rahway, NJ, USA and its affiliates. All rights reserved. <br> US-JRC-00358   &nbsp;&nbsp; 02/23</p>
         <ul>
          <li>
            <a :href="urls.PP1.link" target="_blank">{{ urls.PP1.text }}</a>  
          </li>
          <li>
            <a :href="urls.TC1.link" target="_blank">{{ urls.TC1.text }}</a> 
          </li>
          <li>
            <a :href="urls.CP.link" target="_blank">{{ urls.CP.text }}</a> 
          </li>
         </ul>
        </div>
      </div> 
  
       <transition name="fade">
      <div v-show="showScrollBtn" class="arrow_up" @click="scrollUP">
        <img
          src="@/Assets/Img/arrow-white-icon.svg"
          class="arrow_up-image"
          alt
        />
      </div>
    </transition>
      
    </div>
    <div class="brand-footer__contentFullWidth"> 
      <div class="brand-footer--full">
        <p>This <i> Cancer Therapy Advisor Fast Facts </i> is produced as a basic reminder of important information for healthcare professionals. Readers are advised to consult manufacturers and specialists if questions arise about specific products, treatments, or diseases. The publisher and editors do not assume liability for any errors or omissions. <i>  Cancer Therapy Advisor </i> and <i> Fast Facts </i> are registered trademarks of Haymarket Media, Inc.</p>
      </div> 
    </div> 
    <div class="brand-footer__bottom">
    <div class="brand-footer__bottom--left">
        <div class="brand-footer__link">
          <a :href="urls.PP.link" target="_blank">{{ urls.PP.text }}</a>
          <a :href="urls.TC.link" target="_blank">{{ urls.TC.text }}</a>
        </div>    
      </div> 
      <div class="brand-footer__bottom--right">
        <div class="brand-footer__copyright">
          <p>© 2023 Haymarket Media, Inc. All rights reserved.</p>
        </div>
      </div> 
      
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "BrandFooter",
  data: () => ({
    showScrollBtn: false,
  }),
  computed: {
    ...mapState(["urls"]),
  },
   methods: {
    scrollUP() {
      this.$scrollTo("body", 1000, {
        container: "body",
        easing: "ease-in-out",
        offset: 0,
      });
    },
    showScrollToTop() {
      this.showScrollBtn = window.pageYOffset > 500;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.showScrollToTop);
  },
  destroyed() {
    window.removeEventListener("scroll", this.showScrollToTop);
  },
};
</script>

<style lang="scss" src="./BrandFooter.scss">

</style>

