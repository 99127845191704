<template src="./SubGroupAnalysis.html"></template>

<script>
import SubGroupGraph from "./SubGroupGraph.vue"
export default {
  name: "SubGroupAnalysis",

  components: {
  SubGroupGraph
  },

  props: {
    badgeTitle: String,
    cardTitle: String,
    cardTitleSecondary: String,
    percentageCard__number: String,
    percentageCard__dec: String,
    analysisTitle  : String,
    chartCard: String,
    footnote: String,
    footerGray: String,
    tumoreGrade: String,
    note: String,
    analysisTitleTwo: String,
  
    
  },
};
</script>

<style lang="scss" src="./SubGroupAnalysis.scss" scoped></style>