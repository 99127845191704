<template src="./Home.html"></template>

<script>
import { mapState } from "vuex";
import ScrollDepthAnalytics from "@/mixins/ScrollDepthAnalytics";
import HeroSection from "@/Components/Blocks/HeroSection/HeroSection";
import Login_Auth_Pop_Up from "@/Components/Blocks/Popups/Login_Auth_Pop_Up";
import Login_Cancel_Pop_Up from "@/Components/Blocks/Popups/Login_Cancel_Pop_Up";
import RccStaging from "../Components/Layouts/RccStaging/RccStaging.vue";
import RiskRecurrence from "../Components/Layouts/RiskRecurrence/RiskRecurrence.vue";
import SubGroupAnalysis from "../Components/Layouts/SubGroupAnalysis/SubGroupAnalysis.vue";
import LearnMore from "../Components/Layouts/LearnMore/LearnMore.vue";
import References from "../Components/Layouts/References/References.vue";


export default {
  name: "HomePage",
  components: {
    HeroSection,
    Login_Auth_Pop_Up,
    Login_Cancel_Pop_Up,
    RccStaging,
    RiskRecurrence,
    SubGroupAnalysis,
    LearnMore,
    References,
  },
  data() {
    return {
      isActiveOverlay: false,
      deadInteractivity: true,
      currentSection: 0,
      sections: {},
      sectionScene: [],
      options: {
        container: "body",
        easing: "ease-in",
        offset: 0,
      },

      heroSectoionObj: {
        secondaryTitle: "For certain patients with RCC post nephrectomy",
         takeCloserTitle: "TAKE A CLOSER LOOK",
         mainDes: "Recurrence Incidence May Be Higher Than You Think<sup>1</sup>",
         rcc_cell: "RCC = renal cell carcinoma.",
      },

      rccStagingObj: {
                badgeTitle: "RCC STAGING",
                cardTitle: "Understanding staging in RCC is important to assessing risk of disease recurrence<sup>1</sup>",
                cardTitleSecondary:'Most RCC patients are diagnosed with localized disease<sup>2</sup>',
                subTitle1: "LOCALIZED",
                subTitle2: "REGIONAL",
                subTitle3: "DISTANT",
                Stage1: "Stage I",
                Stage2: "Stage II",
                Stage3: "Stage III",
                Stage4: "Stage IV",
                SubTitleSecondaryOne: "T1, N0, M0",
                SubTitleSecondaryTwo: "T2, N0, M0",
                Stage1SubTitlee: "T1, N0, M0",
                Stage2SubTitlee: "T2, N0, M0", 
                Stage3SubTitlee: "T1 or T2, N1, M0 <br> T3, Any N, M0", 
                Stage4SubTitlee: "T4, Any N, M0 <br> Any T, Any N, M1",   
                Stage3Dec: "STAGE III tumors include T1 and T2 tumors with regional lymph node involvement <u>or</u> T3 tumors, which are defined as not invading beyond Gerota's fascia <u>or</u> into the ipsilateral adrenal gland but have <strong> spread into major veins or perinephric tissues.</strong><sup>2,3</sup>",
                Stage3Dec2:"T3 tumors are <strong> characterized by their invasiveness</strong>, not their size.<sup>4</sup>",
                Stage4Dec: "STAGE IV tumors have grown beyond Gerota's fascia, have <strong> grown into the ipsilateral adrenal gland <u>or</u> have spread to distant lymph nodes and/or other organs</strong><sup>2,3</sup>",
                Stage4Dec2: "T4 tumors are <strong> characterized by their invasiveness,</strong> not their size.<sup>4</sup>",
                footerCard: "STAGE I AND II tumors are <strong> confined to the kidney </strong> and are <strong>classified based on  size </strong> <span class='white-space'>(&#8804; or &#62; 7 cm)<sup>2,3</sup></span>",
                footnote: "M0 = no distant metastases; M1 = distant metastasis; N0 = no regional lymph node involvement; T1 = tumor ≤ 7 cm in greatest dimension, limited to the kidney;<br> T2 = tumor > 7 cm in greatest dimension, limited to the kidney; T3 = tumor extends into major veins or perinephric tissues, but not into the ipsilateral adrenal gland and not beyond Gerota’s fascia; T4 = tumor invades beyond Gerota’s fascia (including contiguous extension into the ipsilateral adrenal gland).",
                footerGrayOne: "For patients with renal cell carcinoma who have localized disease, surgery to remove the tumor, either by partial or radical nephrectomy, is the current standard-of-care treatment.<sup>4,5</sup>",
                footerGrayTwo: "Surgery may be curative for some patients with RCC but others with more invasive tumors are at a higher risk of their cancer coming back.<sup>4,5</sup>",
      },

      riskRecurrenceObj:{
              badgeTitle: "Recurrence Rates in RCC",  
              cardTitle: "Recurrence Rates in RCC for Certain Patients Post Nephrectomy<sup>1</sup>",
              cardTitleSecondary:'Based on an observational analysis, which included 643 patients, using SEER-Medicare data from 2007 to 2016<sup>1</sup>',
              analysisTitleOne: "Analysis Population<sup>1,5</sup>",
              analysisTitleTwo: "Analysis Limitations<sup>1</sup>",
              footnote: "M0 = no distant metastasis; N0 = no regional lymph node involvement; N+ = regional lymph node metastasis; SEER = Surveillance, Epidemiology, and End Results;<br> T = primary tumor; T2 = tumor > 7 cm in greatest dimension, limited to the kidney; T3 = tumor extends into major veins or perinephric tissues, but not into the ipsilateral adrenal gland and not beyond Gerota’s fascia; T4 = tumor invades beyond Gerota’s fascia (including contiguous extension into the ipsilateral adrenal gland).",
              footerGray: "The majority of patients included in this analysis had tumors without nodal involvement.<sup>1,5</sup>",
            },

      subGroupAnalysisObj:{
              badgeTitle: "SUBGROUP ANALYSIS", 
              cardTitleSecondary:'In an analysis of the same SEER-Medicare data from 2007-2016 for a subset of patients with RCC post nephrectomy:',
              cardTitle: "5-YEAR Post Nephrectomy Recurrence Rates<sup>1</sup>",
              percentageCard__number: "45<sup>%</sup>",
              percentageCard__dec: "of patients included in this subgroup analysis with RCC post nephrectomy <strong> EXPERIENCED RECURRENCE<sup>1</sup></strong>",
              analysisTitle: "Subgroup analysis population<sup>1</sup>",
              chartCard: "All Patients With T3N0 Tumors Were at Risk of Recurrence<sup>1,5</sup>",
              tumoreGrade: "How did tumor grade impact RCC recurrence in this analysis?",
              analysisTitleTwo: "Analysis Limitations (above) continued to apply throughout the subgroup analysis.",
              note: "<strong>Note:</strong> Patients were followed from the date of initial nephrectomy until the earliest of recurrence or censoring at (1) death; (2) end of Medicare Part A, B, or D eligibility; and (3) end of data availability on December 31, 2016.<sup>5</sup>",
              footnote: "G = grade; M0 = no distant metastasis; N0 = no regional lymph node involvement; SEER = Surveillance, Epidemiology, and End Results; T2 = tumor > 7 cm in greatest dimension, limited to the kidney; T3 = tumor extends into major veins or perinephric tissues, but not into the ipsilateral adrenal gland and not beyond Gerota’s fascia.",
              footerGray: "Higher rates of recurrence were observed in patients with higher grade tumors.<sup>1,5</sup>",
            },   
            
            LearnMoreObj: {
              mainTitle: "Discuss risk of recurrence with your appropriate patients with RCC post nephrectomy.",
              buttonText: "Learn More",
            },


            refDescObj: {
                mainDesc: "<span>References: </span> <strong>1.</strong> Sundaram M, Song Y, Rogerio JW, et al. Clinical and economic burdens of recurrence following nephrectomy for intermediate high- or high-risk renal cell carcinoma: a retrospective analysis of Surveillance, Epidemiology, and End Results–Medicare data. <i>J Manag Care Spec Pharm</i>. 2022. doi:10.18553/jmcp.2022.22133 <strong>2.</strong> Kidney cancer stages. American Cancer Society. Last revised February 1, 2020. Accessed November 7, 2022. https://www.cancer.org/cancer/kidney-cancer/detection-diagnosis-staging/staging.html <strong>3.</strong> Thakur A, Jain SK. Kidney Cancer: Current Progress in Treatment. <i>World J Oncol</i>. 2011 Aug;2(4):158-165. doi: 10.4021/wjon345w. <strong>4.</strong> Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Kidney Cancer v4.2023. National Comprehensive Cancer Network, Inc. 2023. All rights reserved. Accessed January 17, 2023. To view the most recent and complete version of the guidelines, go online to NCCN.org. <strong>5.</strong> Sundaram M, Song Y, Rogerio JW, et al. Supplementary Appendix to: Clinical and economic burdens of recurrence following nephrectomy for intermediate high- or high-risk renal cell carcinoma: a retrospective analysis of Surveillance, Epidemiology, and End Results–Medicare data. (<i>J Manag Care Spec Pharm</i>. 2022.) doi:10.18553/jmcp.2022.22133",
            },

      publicPath: process.env.BASE_URL,
      microbeAnimationScene_1: {},
      popupTimer: null,
      backplateTimer: null,
      resizeTimer: null,
      initTileProps: [],
      postFontSize: 1,
    };
  },
  computed: {
    ...mapState([
      "userAgent",
      "urls",
      "animationsData",
      "controllerExist",
      "mainMenu",
      "activeSection",
    ]),
    isIE() {
      return this.userAgent.browser.name === "ie";
    },
    routes() {
      return this.$router.options.routes;
    },
  },
  methods: {
    clickAnchor(anchor, offset) {
      if (typeof offset !== "undefined") {
        this.$scrollTo(anchor, 750, {
          container: "body",
          easing: "ease-in",
          offset: offset,
        });
      } else {
        this.$scrollTo(anchor, 750, this.options);
      }
    },
     scrollUP() {
      this.$scrollTo("body", 1000, {
        container: "body",
        easing: "ease-in-out",
        offset: 0,
      });
    },
  },
  mounted() {
    let checkScreenTest = document
      .getElementById("app")
      .classList.contains("screentest");

    if (!this.$route.query.screentest || !checkScreenTest) {
      var checkExist = setInterval(() => {
        if (document.querySelector(".preloadHide")) {
          // this.sections = document.All("section");
          //this.initSectionsProgress();
          //this.initContentAnimation();
          clearInterval(checkExist);
        }
      }, 100);
    }

    // if (window.innerWidth >= 768) {
    //   this.initCardsAnimation();
    // }
  },
  watch: {
    activeSection(newVal) { },
  },
  mixins: [ScrollDepthAnalytics],
};
</script>

<style lang="scss" src="./Home.scss" scoped>

</style>
