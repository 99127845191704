<template src="./RccStaging.html"></template>

<script>
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
export default {
  name: "RccStaging",
  props: {
    badgeTitle: String,
    cardTitle: String,
    cardTitleSecondary: String,
    subTitle1: String,
    subTitle2: String,
    subTitle3: String,
    Stage1: String,
    Stage2: String,
    Stage3: String,
    Stage4: String,
    Stage1SubTitlee: String,
    Stage2SubTitlee: String,
    Stage3SubTitlee: String,
    Stage4SubTitlee: String,
    Stage3Dec: String,
    Stage3Dec2: String,
    Stage4Dec: String,
    Stage4Dec2: String,
    footerCard: String,
    footnote: String,
    footerGrayOne: String,
    footerGrayTwo: String,
  },
  mounted() {

    if (window.innerWidth > 767) {
      gsap.registerPlugin(ScrollTrigger);
        ScrollTrigger.create({
        trigger: ".animationOne",
        markers: false,
        start: "top +=62%",
        onEnter() {
          document.querySelector(".animationOne").classList.add("animation1");
          document.querySelector(".animationTwo").classList.add("animation2");
        },
      });
      gsap.registerPlugin(ScrollTrigger);
        ScrollTrigger.create({
        trigger: ".animationThree",
        markers: false,
        start: "top +=50%",
        onEnter() {
          document.querySelector(".animationThree").classList.add("animation3");
          document.querySelector(".animationFour").classList.add("animation4");
        },
      });

    } else {
      gsap.registerPlugin(ScrollTrigger);
        ScrollTrigger.create({
        trigger: ".animationOne",
        markers: false,
        start: "top center",
        onEnter() {
          document.querySelector(".animationOne").classList.add("animation1");
         
        },
      });
      gsap.registerPlugin(ScrollTrigger);
        ScrollTrigger.create({
        trigger: ".animationTwo",
        markers: false,
        start: "top +=85%",
        onEnter() {
         
          document.querySelector(".animationTwo").classList.add("animation2");
        },
      });
      gsap.registerPlugin(ScrollTrigger);
        ScrollTrigger.create({
        trigger: ".animationThree",
        markers: false,
        start: "top +=65%",
        onEnter() {
          document.querySelector(".animationThree").classList.add("animation3");
        },
      });
      gsap.registerPlugin(ScrollTrigger);
        ScrollTrigger.create({
        trigger: ".animationFour",
        markers: false,
        start: "top +=65%",
        onEnter() {
          document.querySelector(".animationFour").classList.add("animation4");
        },
      });
    }

  }
};
</script>

<style lang="scss" src="./RccStaging.scss" scoped></style>