<template>
  <div :class="{
    isi: true,
    'isi-static': static,
    'isi-hidden': hideElement,
    'isi-opened': openedIsi && !hideElement,
    'isi-opened-menu': mobileMenuState,
  }">

    <div class="isi__container">
      <button class="isi__expand-button" @click="toggleISI" v-if="!static"></button>
      <div class="isi__content">
        Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin
        literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney
        College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and
        going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum
        comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by
        Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance.
        The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.

        Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin
        literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney
        College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and
        going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum
        comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by
        Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance.
        The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.

        Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin
        literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney
        College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and
        going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum
        comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by
        Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance.
        The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.
      </div>
    </div>

  </div>

</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Isi",
  components: {},
  props: {
    static: {
      type: Boolean,
      default: false,
    },
    hideElement: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      openedIsi: false,
    };
  },
  computed: {
    ...mapState([
      "userAgent",
      "urls",
      "mobileMenuState",
      "activeSection",
      "mainMenu",
    ]),
  },
  methods: {
    toggleISI() {
      this.openedIsi = !this.openedIsi;
      document.querySelector("html").style.overflow = this.openedIsi
        ? "hidden"
        : "visible";
    },
  },
};
</script>

<style lang="scss" src="./Isi.scss" >

</style>

