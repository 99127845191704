<template src="./LearnMore.html"></template>

<script>
export default {
  name: "LearnMore",
  props: {
  mainTitle: String,
  buttonText: String,
  },
};
</script>

<style lang="scss" src="./LearnMore.scss" scoped></style>