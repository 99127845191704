import Vue from "vue";
import Vuex from "vuex";
import userAgent from "./modules/userAgent";
import animationsData from "./animationsData";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isScrollLock: false,
    mobileMenuState: false,
    activeSection: 0,
    urls: {
      PI: {
        link: "https://www.tukysahcp.com/pdf/TUKYSA_Full_Ltr_Master.pdf",
        text: "Full Prescribing Information",
      },
      MG: {
        link: "https://www.merck.com/product/usa/pi_circulars/w/welireg/welireg_mg.pdf",
        text: "Medication Guide",
      },
      PP: {
        link: "https://www.haymarketmediaus.com/haymarket-media-medical-privacy-policy/",
        text: "Privacy Policy",
      },
      TC: {
        link: "https://www.haymarketmediaus.com/haymarket-media-inc-us-terms-conditions/",
        text: "Terms of Conditions",
      },
      LN: {
        link: "#",
        text: "Legal Notice",
      },
      CU: {
        link: "#",
        text: "Contact Us",
      },
      PP1: {
        link: "https://www.msdprivacy.com/us/en/",
        text: "Privacy policy",
      },
      TC1: {
        link: "https://www.merck.com/terms-of-use/",
        text: "Terms of use",
      },
      CP: {
        link: "https://www.keytrudahcp.com/?utm_source=google&utm_medium=cpc&utm_campaign=Keytruda+HCP_Pan-Tumor_Brand_Exact&utm_content=Brand_Exact&utm_term=keytruda+merck&gclid=CjwKCAiA_vKeBhAdEiwAFb_nrSoT0c57wm_LPzTPjC2z5xuMoPUjniD1AQqVnMLRszbkjP3IAKj-NBoCQ-AQAvD_BwE&gclsrc=aw.ds",
        text: "Cookie preferences",
      },
      HW: {
        link: "https://www.welireghcp.com/",
        text: "WELIREG HCP Website",
      }
    },
    animationsData,
    controllerExist: false,

    el: '#nav',
   
    mainMenu: [
      {
        id: 1,
        title: "RCC Staging",
        anchorRef: "#sectionOne",
      }, 
      {
        id: 2,
        title: "Risk Recurrence of RCC",
        anchorRef: "#sectionTwo",
      },
      {
        id: 3,
        title: "Subgroup Analysis",
        anchorRef: "#sectionThree",
      },
      {
        id: 4,
        title: "Learn More",
        anchorRef: "#sectionFour",
      },      
    ],
  },
  
  modules: {
    userAgent,
  },
  mutations: {
    SET_SCROLL_CONTROLLER_STATE(state, value) {
      state.controllerExist = value;
    },
    TOGGLE_MOBILE_MENU(state, value) {
      state.mobileMenuState = value;
    },
    SET_ACTIVE_SECTION(state, value) {
      state.activeSection = value;
    },
  },
 
});
