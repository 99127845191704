<template>
  <div :class="{
    container__header: true,
    '': this.isScrolled,
  }">
    <div class="container__header__wrapper" :class="$route.name">
      <div class="container__header-left" @click="scrollUP">
        <!-- <div class="container__header-left-logo">
          <router-link :to="{ name: 'home' }"><img src="@/Assets/Img/Keytruda-Logo.svg" alt="Ask The Expert" /></router-link>
        </div> -->
        <div class="container__header-left-content">
          <p>This program has been created and funded by Merck.</p>
        </div>
        <!-- <div class="container__header-links">
          <ul class="dropdown" :class="{ isOpen }">
            <a href="#"><img src="@/Assets/Img/pdf-icon.svg" alt="" />Prescribing Information </a>
            <a href="#"><img src="@/Assets/Img/medical-Cross-Icon.svg" alt="" />Medication Guide </a>
          </ul>
        </div> -->
      </div>
      <!--	Mobile Menu	-->
      <div class="mobile_menu_toggle" :class="{ active_menu: this.mobileMenuState }" @click="toggleMenu"></div>
      <div class="collapse_wrapper mobile_menu_nav" :class="{ 'collapse_wrapper--open': this.mobileMenuState }">
        <div class="collapse_wrapper-content">
         <ul class="dropdown" :class="{ isOpen }">
            <li style="display:none;"></li>
            <li v-for="mem in menuTop">    
            <a 
            v-bind:class="{ active: (mem.id === activeId) }" 
            v-on:click="selectMember(mem.id); handle_function_call(mem.scrollId);" 
            v-smooth-scroll> 
            {{ mem.status }}    
            </a>            
          </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";



export default {
  name: "Header",
  components: {},
  
  data() {
    return {
      isOpen: false,
      isScrolled: false,
      isActive: false,
      activeId : 0,
      options: {
        container: "body",
        easing: "ease-in",
        offset: -150,
      },
      menuTop: [
      { id :1, status: 'RCC Staging', scrollId: 'scrollToSection1', },
      { id :2, status: 'Recurrence Rates in RCC', scrollId: 'scrollToSection2', },
      { id :3, status: 'Subgroup Analysis', scrollId: 'scrollToSection3', },
      { id :4, status: 'Learn More', scrollId: 'scrollToSection4', },
    ]
    };
  },
  computed: {
    ...mapState([
      "userAgent",
      "urls",
      "mobileMenuState",
      "activeSection",
      "mainMenu",
    ]),
    routes() {
      return this.$router.options.routes;
    },
    prevpageid() {
      if (this.$route.meta.id == 1) {
        return this.$router.options.routes.length - 1;
      } else {
        return this.$route.meta.id - 1;
      }
    },
    nextpageid() {
      if (this.$route.meta.id == this.$router.options.routes.length - 1) {
        return "1";
      } else {
        return this.$route.meta.id + 1;
      }
    },
  },
  methods: {

   handle_function_call(function_name) {
    this[function_name]()
  },

    scrollToSection1() {
      var scrollDiv = document.getElementById("section1").offsetTop;
      window.scrollTo({ top: scrollDiv - 150, behavior: 'smooth', scrollBehavior: 'smooth' });
      return false;
    },
    scrollToSection2() {
      var scrollDiv = document.getElementById("section2").offsetTop;
      window.scrollTo({ top: scrollDiv - 130, behavior: 'smooth', scrollBehavior: 'smooth' });
      return false;
    },
    scrollToSection3() {
      var scrollDiv = document.getElementById("section3").offsetTop;
      window.scrollTo({ top: scrollDiv - 110, behavior: 'smooth', scrollBehavior: 'smooth' });
      return false;
    },
    scrollToSection4() {
      var scrollDiv = document.getElementById("section4").offsetTop;
      window.scrollTo({ top: scrollDiv - 110, behavior: 'smooth', scrollBehavior: 'smooth' });
      return false;
    },

    scrollToTop() {
      this.$scrollTo("#head-section", 500);
    },
    scrollToAnchor(anchor) {
      if (this.userAgent.window.width < 1024 && anchor !== "#head-section") {
        this.toggleMenu();
      }
      this.$scrollTo(anchor, 750, this.options);
    },
    toggleMenu() {
      this.$store.commit("TOGGLE_MOBILE_MENU", !this.mobileMenuState);
    },
    detectScrollYPosition() {
      this.isScrolled = window.pageYOffset > 0;
     
      if(this.mobileMenuState == true){
        this.$store.commit("TOGGLE_MOBILE_MENU", !this.mobileMenuState);
      }
    },
    mouseover: function () {
      this.isOpen = true;
      document.getElementById("dropdownNav").style.top = "64px";
    },

    mouseleave: function () {
      this.isOpen = false;
      document.getElementById("dropdownNav").style.top = "-64px";
    },
    scrollFunction() {
    

      if (

        document.body.scrollTop > 20 ||

        document.documentElement.scrollTop > 20

      ) {

        //document.getElementById("dropdownNav").style.top = "64px";
        document.querySelectorAll(".dropdown").forEach((el) => {
          el.classList.add("isOpen");
        });

      } else {

        //document.getElementById("dropdownNav").style.top = "-64px";
        document.querySelectorAll(".dropdown").forEach((el) => {
          el.classList.remove("isOpen");
        });
      }

    },

     selectMember: function (id) {              
    this.activeId = id;
    this.$store.commit("TOGGLE_MOBILE_MENU", !this.mobileMenuState);
    },

    scrollUP() {
      this.$scrollTo("body", 1000, {
        container: "body",
        easing: "ease-in-out",
        offset: 0,
      });
    },
    
  },
  watch: {
    mobileMenuState() {
      const $body = document.querySelector("body");

      // if (this.mobileMenuState) {
      //   document.querySelector("html").style.overflow = "visible";
      //   document.querySelector("html").style.overflow = "visible";
      //   $body.classList.add("popup-open");
      // } else {
      //   document.querySelector("html").style.overflow = "auto";
      //   document.querySelector("html").style.overflow = "visible";
      //   $body.classList.remove("popup-open");
      // }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.detectScrollYPosition);
    window.addEventListener("scroll", this.scrollFunction);

    document
      .querySelector(".mobile_menu_nav .collapse_wrapper-content ul li")
      .addEventListener("click", function () {
        document.querySelector(".mobile_menu_toggle").click();
      });
  },

  
};
</script>

<style lang="scss" src="./Header.scss" scoped>

</style>
