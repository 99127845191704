<template>
  <div>
    <div class="maingraphCard">
      <div class="maingraphCard__indexGraph">
        <div class="graphIndex">
          <ul>
            <li><span class="without"></span>% without recurrence</li>
            <li><span class="with"></span>% with recurrence</li>
          </ul>
        </div>
      </div>
      <div class="maingraphCard__card">
        <div class="maingraphCard__card--graphCircle">
          <div class="maingraphCard__card--graphCircle--inner--left pieChartinner">
            <img class="level0" src="@/Assets/Img/pie37per.png" alt="" />
            <img class="level1" src="@/Assets/Img/pie50per.png" alt="" />
            <img class="level2" src="@/Assets/Img/pie72per.png" alt="" />
            <svg viewBox="0 0 34 34" class="pieCircular">
            <path class="pieCircularStroke"
              d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
            />            
          </svg>
            <span class="pieNumber">37<sup class="sup">%</sup></span>
          </div>
        </div>
        <div class="maingraphCard__card--graphTitle">
          <h2 class="introarea" >
            T3 Grade {{valueOne}}
            <span>Experienced Recurrence</span>
          </h2>
          <p>(n={{valueSecond}})<sup>5</sup></p>
        </div>
        <div class="maingraphCard__card--graphButtons">
          <ul class="buttonList">
            <li class="active li37"  @click="changevalue(37)"><button >G1-G2</button></li>
            <li @click="changevalue(50)" class="li50" ><button  >G3</button></li>
            <li  @click="changevalue(72)" class="li72"><button>G4</button></li>
          </ul>
          <div class="buttonGrade">Select EACH Grade Above To Learn More</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubGroupGraph",
  props: {
    name: String,
  },
  data() {
    return {
      valueOne: '1-2',
      valueSecond: '297',
       isActive: true,
      isError: false
    }
  }
  ,mounted() {
    window.addEventListener("scroll", function () {
       var pieChartinner = document.querySelector(".pieChartinner");
      var positionMorality = pieChartinner.getBoundingClientRect();  
       if (positionMorality.top < window.innerHeight-400  && positionMorality.bottom >= 0) { 
             pieChartinner.classList.add("pieChartAnimation1");
       }
      })
      },
  methods: {
    changevalue(gval) {    
      var buttonListli = document.querySelectorAll('.buttonList li');
      var pieChart = document.querySelector('.pieChartinner');          
      var pieNumber = document.querySelector('.pieNumber');  
      var introarea = document.querySelector('.introarea');  
      pieChart.classList.remove("pieChartAnimation1");
      introarea.classList.remove("fadeIn");
      setTimeout(function(){        
        pieChart.classList.add("pieChartAnimation1");
      introarea.classList.add("fadeIn");
      }, 50)    
      pieNumber.innerHTML= gval+"<sup>%</sup>";
      buttonListli.forEach(function(li) {
        li.classList.remove("active");
      });
      document.querySelector('.li'+gval).classList.add("active");
      
      if(gval === 37){
        document.querySelector('.level0').style.display = "block";
        document.querySelector('.level1').style.display = "none";
        document.querySelector('.level2').style.display = "none";
        this.valueOne = "1-2",
        this.valueSecond = "297"
      }
      else if (gval === 50){
        document.querySelector('.level0').style.display = "none";
        document.querySelector('.level1').style.display = "block";
        document.querySelector('.level2').style.display = "none";
         this.valueOne = "3",
        this.valueSecond = "250"
      }
      else {
        document.querySelector('.level0').style.display = "none";
        document.querySelector('.level1').style.display = "none";
        document.querySelector('.level2').style.display = "block";        
        this.valueOne = "4",
        this.valueSecond = "64"
      }
      this.isActive = !this.isActive;
    }
  }
};
</script>

<style lang="scss" scoped>
.maingraphCard {
  &__indexGraph {
    position: relative;
    .graphIndex {
      position: absolute;
      right: 20px;

      @include media("<phone") {
        position: relative;
        right: 0;
      }

      @include media("<tablet") {
        position: relative;
        right: 0;
      }

      ul {

        @include media("<phone") {
          display: flex;
        justify-content: space-between;
      }


        li {
           font-weight: $fontWeightNormal;
          font-size: 21.75px;
          line-height: $heroLineHeight33;
          color: $darkGray;
          display: flex;
          align-items: center;
          gap: 11px;
          margin-bottom: 8px;

          @include media("<phone") {
            font-size: 13.1193px;
            line-height: $heroLineHeightM;
          }

          .without {
            width: 21px;
            height: 21px;
            border-radius: 50%;
            background: $pieChart;
            display: inline-block;
          }

          .with {
            width: 21px;
            height: 21px;
            border-radius: 50%;
            background: $secondaryColor;
            display: inline-block;
          }
        }
      }
    }
  }

  &__card {
    margin-top: 50px;
   
    &--graphCircle {
      max-width: 330px;
      margin: 41px auto;
      border-radius: 50%;
     
    }

    &--graphButtons {
      .buttonList {
        margin: 50px auto 40px;
        list-style-type: none;
        max-width: 350px;
        display: flex;
        justify-content: space-between;

         @include media("<phone") {
           gap: 0px;
           margin: 20px auto;
           justify-content: space-between;
           margin: 24.98px auto 27.42px;
         }

        li {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          background-color: $graphBg;
          align-items: center;
          justify-content: center;
          display: flex;
           @include media("<phone") {
          width: 100px;
          height: 100px;
          font-size: $fontSize24;
          line-height: $headerLineHeight24;
          }

          button {
            font-weight: 700;
            font-size: $fontSize24;
            line-height: $headerLineHeight24;
            width: 100%;
            height: 100%;
            border: none;
            background: none;
            color: $primaryColor;
            cursor: pointer;

             @include media("<phone") {
              font-size: $fontSize24;
              line-height: $headerLineHeight24;
              padding: 0px;
          }
          }
          &.active {
            background: $secondaryColor;

            button {
              color: $white;
            }
          }

           &:hover {
            background: $secondaryColor;

            button {
              color: $white;
            }
          }
        }
      }

      .buttonGrade {
        max-width: 495px;
        background: rgba(186, 184, 184, 0.2);
        border-radius: 10px;
        font-weight: $fontWeightBold;
        font-size: $fontSize18;
        line-height: $heroLineHeight28;
        padding: 5px 20px;
        text-transform: uppercase;
        text-align: center;
        margin: 0 auto;
        color: $primaryColor;

         @include media("<phone") { 
           font-size: 10.9675px;
           line-height: 19px;
         }
      }
    }

    &--graphTitle {
      text-align: center;
      margin: 0 auto;
      h2 {
        font-weight: $fontWeightBold;
        font-size: $fontSize36;
        line-height: $heroLineHeightX;
        color: $primaryColor;
        margin-bottom: 10px;
         @include media("<phone") { 
          font-size: $fontSize26;
          line-height: $heroLineHeight28;
          margin-bottom: 6.75px;
         }
         span{
          display: block;
          font-size: $fontSize24;
          line-height: $heroLineHeightX;
          @include media("<phone") { 
          font-size: $fontSize20;
          line-height: $heroLineHeight28;
         }
         }
      }
      p {
        font-weight: $fontWeightNormal;
        font-size: $fontSize24;
        line-height: $heroLineHeightN;
        color: $primaryColor;
        @include media("<phone") { 
         font-weight: 400;
        font-size: 17px;
        line-height: 21px;
         }
      }
    }
  }
}

@property --gval {
  syntax: "<number>";
  inherits: true;
  initial-value: 0;
}

.pie {
  --gval: 20;
  --b: 163px;
  --c: darkred;
  --w: 314px;
  aspect-ratio: 1;
  position: relative;
  place-content: center;
  font-size: 25px;
  font-weight: bold;
  font-family: sans-serif;
}
span.pieNumber {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 170px;
  color: #fff;
  font-weight: 700;
  font-size: 38px;
  line-height: 56px;

   @include media("<phone") {
      margin-left: 124px;
      font-weight: 700;
      font-size: 28.0478px;
      line-height: 38px;
    }

  sup {
    display: flex;
    align-items: baseline;
    font-weight: 400;
  }
}
.pie:before,
.pie:after {
  content: "";
  position: absolute;
  border-radius: 50%;
}
.pie:before {
  inset: 0;
  background: radial-gradient(farthest-side, var(--c) 98%, #0000) top/var(--b)
      var(--b) no-repeat,
    conic-gradient(var(--c) calc(var(--gval) * 1%), #0000 0);
  -webkit-mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--b)),
    #000 calc(100% - var(--b))
  );
  mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--b)),
    #000 calc(100% - var(--b))
  );
}
.pie:after {
  inset: calc(50% - var(--b) / 2);
  background: var(--c);
  transform: rotate(calc(var(--gval) * 3.6deg))
    translateY(calc(50% - var(--w) / 2));
}
.animate {
  animation: ganimation 1s 0s both;
}
.no-round:before {
  background-size: 0 0, auto;
}
.no-round:after {
  content: none;
}
@keyframes ganimation {
  from {
    --gval: 0;
  }
  

}

.fadeIn {
  animation: fadeIn linear 2s;
}
@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}
.pieChartinner{
    position: relative;    
    overflow: hidden;
    border-radius: 100%;
    height: 309px;
    width: 308px;
    margin: 0 auto;
        img{
          width: 317px;
          position: absolute;
          height: 317px;
          top: -5px;
          left: -3px;
        }
        .level0{
              display: block;
        }
        .level1{
              display: none;
        }
        .level2{
              display: none;
        }

        @include media("<verySmallPhone") {
          margin-left: -6px;
        }
    }
    .pieChartAnimation1{

    .pieCircularStroke {
      animation: progress 5s linear forwards;
    }

  }

.pieCircular {
  width: 178px;
  height: 320px;
  overflow: visible;
  position: absolute;
  top: -14px;
  left: 56px;
  z-index: 99;
  -webkit-transform: rotate(-1deg);
  transform: rotate(-1deg);
  }
  
  .pieCircularStroke {
    fill: none;
    stroke-width: 31.8;
    stroke: #F0E9E6;
    stroke-dasharray: 175;
    stroke-dashoffset: 350;
  }

  @keyframes progress {
 
    to {
      stroke-dashoffset: 175;
    }
  }
</style>
