<template src="./RiskRecurrence.html"></template>

<script>
import RiskRecGraph from "./RiskRecGraph.vue"
export default {
  name: "RiskRecurrence'",
  components: {
  RiskRecGraph
  },

  
  props: {
    badgeTitle: String,
    cardTitle: String,
    cardTitleSecondary: String,
    analysisTitleOne: String,
    analysisTitleTwo: String,
    footnote: String,
    footerGray: String,
  },

   data() {
    return {
      riskRecGraphObj: {
        name: "vipa",
      }
    }},

};
</script>

<style lang="scss" src="./RiskRecurrence.scss" scoped></style>