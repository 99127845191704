<template>
  <div @click="scrollUP" :class="{ container__headerTop: true, '': this.isScrolled, }">
    <div class="container__headerTop__wrapper" :class="$route.name">
      <div class="container__headerTop--left">
        <div class="container__headerTop--leftSection">         
        </div>
        <div class="container__headerTop--centerSection">         
        </div>
        <div class="container__headerTop--rightSection">
          <img src="@/Assets/Img/CTA-Logo.svg" alt="Cancer-Therapy-Advisor" />
          <img src="@/Assets/Img/FF-Logo.svg" alt="Fast-Fact-Logo" />
        </div>
        </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "HeaderTop",
  components: {},
  data() {
    return {
      isOpen: false,
      isScrolled: false,
      options: {
        container: "body",
        easing: "ease-in",
        offset: -150,
      },
    };
  },
  computed: {
    ...mapState([
      "userAgent",
      "urls",
    ]),
    routes() {
      return this.$router.options.routes;
    },
  },
  methods:{
    scrollUP() {
      this.$scrollTo("body", 1000, {
        container: "body",
        easing: "ease-in-out",
        offset: 0,
      });
    }
  },
  watch:{},
  mounted(){},
};
</script>

<style lang="scss" src="./HeaderTop.scss" scoped>

</style>
