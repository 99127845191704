<template>
  <div>
    <div id="goTop" :class="['heroSection', heroSecClass]">
      <div class="heroSection__container">
        <div class="heroSection__content">      
         <div class="heroSection__content--heading">
          <img src="@/Assets/Img/Kidney-Hex.svg" alt="" />
          <h2 v-html="secondaryTitle"></h2>
         </div>
         <div class="heroSection__content--intoduction">
         <h2 class="takeCloser" v-html="takeCloserTitle"></h2>
         <h3 v-html="mainDes"></h3>
         <p class="rcc_cell" v-html="rcc_cell"></p>
         </div>
        </div>
      </div>
      <div class="circleRCC">
    <img src="@/Assets/Img/Tissue-Circle-LG.png" alt="" />
  </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: "HeroSection",

  props: {
    secondaryTitle: String,
    takeCloserTitle: String,
    mainDes: String,
    rcc_cell: String,
    heroSecClass: String,
   },
};
</script>

<style lang="scss" src="./HeroSection.scss" scoped>
 
</style>

